AFRAME.registerComponent("thumbstick-look", {
    schema: {
        enabled: {default: true},
        rotationTargetId: {type: "string"},
        rotationSpeed: {default: 0.523599},
    },

    init: function () {
        this.rotationTarget = undefined;
        this.prevX = 0;

        this.el.addEventListener("thumbstickmoved", this.onThumbstickMoved.bind(this));
    },

    getRotationTarget: function () {
        if (this.rotationTarget === undefined) {
            this.rotationTarget = document.getElementById(this.data.rotationTargetId);
        }
        return this.rotationTarget;
    },

    onThumbstickMoved: function (evt) {
        if (!this.data.enabled) return;

        var isInLeftZone = (x) => x <= -0.95;
        var isInRightZone = (x) => x >= 0.95;

        if (isInLeftZone(evt.detail.x) && !isInLeftZone(this.prevX))
            this.getRotationTarget().object3D.rotation.y -= this.data.rotationSpeed;

        if (isInRightZone(evt.detail.x) && !isInRightZone(this.prevX))
            this.getRotationTarget().object3D.rotation.y += this.data.rotationSpeed;

        this.prevX = evt.detail.x;
    },
});
